import "./SnowComponent.scss"

const SnowComponent: React.FC = () => {
    const snowList = [];
    for(let i = 0; i < 50; i++){
        snowList.push(<div key={`snowFlake_${i}`} className='snowflake'></div>);
    }
    return (
        <div className='snow-container'>
            <div className='snow-wrapper'>
                {snowList}
            </div>
        </div>
    );
}

export default SnowComponent;
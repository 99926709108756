import {Overlay, Spinner} from "@blueprintjs/core";
import './LoadingComponent.scss';

export enum LoadingComponentDisplayStyle {
    FullScreen,
    InLine
}

interface Props {
    message?: string;
    displayStyle?: LoadingComponentDisplayStyle;
}

export default function LoadingComponent(
    {message = 'Loading...', displayStyle = LoadingComponentDisplayStyle.FullScreen}: Props) {
    return (
        <Overlay
            className='ews-loading-container'
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            enforceFocus={true}
            hasBackdrop={true}
            isOpen={true}
            usePortal={displayStyle == LoadingComponentDisplayStyle.InLine}
        >
            <div className='ews-loading-wrapper'>
                <div className='ews-loading-item'>
                    <Spinner intent='primary' size={150}/>
                </div>
                <div className='ews-loading-item'>
                    <h1 className='bp4-heading' >{message}</h1>
                </div>
            </div>
        </Overlay>
    )


}
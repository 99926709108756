import {observer} from "mobx-react";
import {useRootStore} from "../core/stores/RootStore";
import {useEffect, useState} from "react";
import './SelectionResultComponent.scss';

const SelectionResultComponent: React.FC = () => {
    const {commonStore} = useRootStore();
    const [isRendering, setIsRendering] = useState(true);
    const [loopInterval, setLoopInterval] = useState(50);
    const [counter, setCounter] = useState(0);
    const [selectionCompleted, setSelectionCompleted] = useState(false);

    const [name, setName] = useState<string>(
        commonStore.participants.length > 0 ?
            commonStore.participants[0].name : ""
    );

    useEffect(() => {
        if (isRendering) {
            const i = setInterval(() => {
                setName(commonStore.participants[Math.floor(Math.random() * commonStore.participants.length)].name);
            }, loopInterval);
            return () => clearInterval(i);
        } else if (counter < 60) {
            const i = setInterval(() => {
                setName(commonStore.participants[Math.floor(Math.random() * commonStore.participants.length)].name);
                setCounter(counter + 1);
            }, loopInterval);
            return () => clearInterval(i);
        } else {
            setName(commonStore.selectedSecretSanta?.name ?? "");
            setSelectionCompleted(true);
        }
    });

    useEffect(() => {
        if (commonStore.selectedSecretSanta) {
            setIsRendering(false);
        }
    }, [commonStore.selectedSecretSanta]);

    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <div className='selectionName' style={{textAlign: 'center'}}>
                <h4 style={{marginBottom: 0}}>YOUR SECRET SANTA IS:</h4>
                <h2 className={`christmas-font name-elem ${selectionCompleted ? 'selectionCompleted' : ''}`}
                    style={{fontSize: "2.25rem", margin: 0}}>{name}</h2>
            </div>
        </div>
    )
}

export default observer(SelectionResultComponent);
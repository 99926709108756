import axios, {AxiosError, AxiosResponse} from "axios";
import { AppPaths } from "../../AppPaths";
import {history} from "../../index";

const sleep = () => new Promise(resolve => setTimeout(resolve, 1000));

axios.defaults.baseURL = '/api/';
axios.defaults.withCredentials = true;

const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.request.use(async (config) => {
    // TO-DO: Fix token from state
    // const token = store.getState().account.user?.jwtToken;
    // if (token) config.headers!.Authorization = `Bearer ${token}`;
    return config;
});

axios.interceptors.response.use(async response => {
    return response;
}, async function (error: any) {
    const {data, status} = error.response!;
    switch (status) {
        case 400:
            throw data;
        case 401:
            history.push(AppPaths.AccessDenied.Url);
            break;
        case 403:
            history.push(AppPaths.AccessDenied.Url);
            break
        case 404:
            history.push(AppPaths.NotFound.Url)
            break;
        case 406:
            history.push(AppPaths.DisabledFeature.Url, data)
            break;
        case 500:
        case 504:
            history.push(AppPaths.ServerError.Url, data)
            break;
        default:
            break;
    }
    return Promise.reject(error.response);
});

const ApiUtil = {
    get: (url: string, params?: any) => {
        const apiParams = new URLSearchParams();
        if(params){
            Object.keys(params).forEach((key: any) => {
                if(params[key] !== null && params[key] !== undefined)
                {
                    apiParams.append(key, params[key]);
                }
            });
        }
        return axios.get(url, {params: apiParams}).then(responseBody);
    },
    post: (url: string, body?: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body?: {}) => axios.put(url, body).then(responseBody),
    patch: (url: string, body?: {}) => axios.patch(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
}

export default ApiUtil;

interface HandleApiBadRequestProps {
    error: any;
    redirectFunc?: () => void;
    toastMessage?: string;
    // TO-DO: Fix to use formik
    // @ts-ignore
    formSetErrorFunc?: (name: string, error: ErrorOption, options?: ({shouldFocus: boolean} | undefined)) => void;
}

export function HandleApiBadRequest({error, redirectFunc, toastMessage, formSetErrorFunc}: HandleApiBadRequestProps){
    console.log(error);
    let errorMessage = error.errorMessage;
    if (errorMessage) {
        errorMessage.forEach((error: any) => {
            if(error.member && formSetErrorFunc != undefined){
                formSetErrorFunc(error.member, {message: error.message});
            } else {
                // TO-DO: fix toast notifications
                // toast.error(error.message);
            }
        });
    } else if(toastMessage) {
        // TO-DO fix toast notifications
        // toast.error(toastMessage);
    }
    if(redirectFunc != undefined){
        redirectFunc();
    }
}
import { createContext, useContext } from "react";
import { CommonStore } from "./CommonStore";

interface RootStore {
    commonStore: CommonStore;
}

export const rootStore: RootStore = {
    commonStore: new CommonStore()
}

export const RootStoreContext = createContext(rootStore);

export function useRootStore() {
    return useContext(RootStoreContext);
}
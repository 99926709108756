import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserHistory} from "history";
import {rootStore, RootStoreContext} from './core/stores/RootStore';
import CustomRouter from "./shared/CustomRouter";
import './index.scss';

export const history = createBrowserHistory();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <RootStoreContext.Provider value={rootStore}>
      <CustomRouter history={history}>
          <App />
      </CustomRouter>
  </RootStoreContext.Provider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

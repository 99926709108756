export const AppPaths: any = {
    Home: {
        Path: "/",
        Url: '/'
    },
    AccessDenied: {
        Path: "/access-denied",
        Url: "/access-denied"
    },
    DisabledFeature: {
        Path: "/feature-unavailable",
        Url: "/feature-unavailable"
    },
    ServerError: {
        Path: "/server-error",
        Url: "/server-error"
    },
    NotFound: {
        Path: "/not-found",
        Url: "/not-found"
    }
}
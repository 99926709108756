import React, {useCallback, useEffect} from 'react';
import {useRootStore} from "./core/stores/RootStore";
import LoadingComponent from "./shared/LoadingComponent";
import {observer} from "mobx-react";
import './App.scss';
import SnowComponent from "./features/SnowComponent";
import TitleComponent from "./features/TitleComponent";
import NameSelectorComponent from "./features/NameSelectorComponent";
import SelectionResultComponent from "./features/SelectionResultComponent";

function App() {
    const {commonStore} = useRootStore();

    const initApp = useCallback(async () => {
        await commonStore.getParticipants();
        await commonStore.loadPreviouslySelected();
    }, [commonStore.getParticipants, commonStore.loadPreviouslySelected]);

    useEffect(() => {
        initApp().then(() => commonStore.setLoaded());
    }, [initApp]);

    if (commonStore.isLoading) return <LoadingComponent message='Initialising app...'/>

    return (
        <div className="App">
            <div className="main">
                <TitleComponent />
                <NameSelectorComponent />
                {commonStore.participant && (<SelectionResultComponent />)}
            </div>
            <SnowComponent/>
        </div>
    );
}

export default observer(App);

import {observer} from "mobx-react";
import {useRootStore} from "../core/stores/RootStore";
import {participantModel} from "../core/models/participantModel";
import {Button, HTMLSelect, IOptionProps} from "@blueprintjs/core";
import {useEffect, useState} from "react";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import * as Yup from 'yup';
import SelectComponent from "../shared/SelectComponent";

const NameSelectorComponent: React.FC = () => {
    const {commonStore} = useRootStore();
        
    const validationSchema = Yup.object().shape({
        participantId: Yup.string().test('validation-check', 'Name selection is required', (value, context) => {
            if (!value || value === '') {
                return false;
            }
            return true;
        })
    });
    
    return (
        <>
            <Formik 
                initialValues={{participantId: commonStore.participant?.participantId ?? ""}}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    var participant = commonStore.participants.find(x => x.participantId == values.participantId);
                    if(participant){
                        commonStore.setParticipant(participant);
                        commonStore.startSelection();
                    }
                }}>
                {(props: FormikProps<{participantId: string}>) => (
                    <Form>
                        <div style={{textAlign: 'center', marginBottom: '.5rem'}}>
                            <SelectComponent />
                        </div>
                        <div style={{textAlign: 'center', marginBottom: '.5rem'}}>
                            <Button disabled={!props.isValid || commonStore.participant != undefined} intent='success' large type='submit'>Find your Secret Santa</Button>
                        </div>
                    </Form>
                )}
            </Formik>
            
            
        </>
    );
}

export default observer(NameSelectorComponent);
import {Router} from "react-router-dom";
import React from "react";
import { Navigator } from 'react-router-dom';

interface Props {
    children: JSX.Element;
    history:  any;
}

export default function CustomRouter ({children, history} : Props) {
    const [state, setState] = React.useState({
        action: history.action,
        location: history.location,
    });

    React.useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router
            children={children}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        />
    );
};
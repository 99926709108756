import {makeAutoObservable} from "mobx";
import {participantModel} from "../models/participantModel";
import apiUtil from "../utilities/apiUtil";

export class CommonStore {
    isLoading = true;
    participants: participantModel[] = [];
    participant?: participantModel = undefined;
    selectionStarted = false;
    selectedSecretSanta?: participantModel = undefined;
    
    constructor(){
        makeAutoObservable(this);
    }
    
    setLoaded = () => {
        this.isLoading = false;
    }
    
    getParticipants = async () => {
        var data = await apiUtil.get("Participants/GetAll");
        if(data.success){
            this.participants = data.model;
        }
    }
    
    loadPreviouslySelected = async () => {
        var participant = localStorage.getItem('participant');
        if(participant) {
            this.participant = JSON.parse(participant);
            await this.getSecretSanta();
        }
    }
    
    setParticipant = (participant: participantModel) => {
        this.participant = participant;
        localStorage.setItem('participant', JSON.stringify(participant));
        this.getSecretSanta().then();
    }
    
    getSecretSanta = async () => {
        var data = await apiUtil.post("Participants/GetSelected", this.participant);
        if(data.success){
            this.selectedSecretSanta = data.model;
        }
    }
    
    startSelection = () => {
        this.selectionStarted = true;
    }
}
import React from "react";
import "./TitleComponent.scss";

const TitleComponent: React.FC = () => {

    return (
        <>
            <div className="title-container">
                <h1 className="christmas-font">
                    <div className="word-container">
                        <span>P</span>
                        <span>h</span>
                        <span>i</span>
                        <span>l</span>
                        <span>o</span>
                    </div>
                    <div className="word-container">
                        <span>S</span>
                        <span>e</span>
                        <span>c</span>
                        <span>r</span>
                        <span>e</span>
                        <span>t</span>
                    </div>
                    <div className="word-container">
                        <span>S</span>
                        <span>a</span>
                        <span>n</span>
                        <span>t</span>
                        <span>a</span>
                    </div>
                </h1>
            </div>
            <div className='bp4-running-text' style={{textAlign: 'center'}}>
                <h3 className="bp4-heading" style={{marginBottom: '.25rem'}}>THE RULES:</h3>
                <p>Budget is £35<br/>
                Keep it a secret!<br/>
                No Swapping or sharing!</p>
                <p><span style={{fontWeight: 'bold'}}>Once you have selected your name you cannot change your name. If you select the wrong name you will be shamed.</span><br/>
                If you forget, just return here to find out who your secret santa is.</p>
            </div>
        </>
    )
}

export default TitleComponent
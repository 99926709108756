import {useRootStore} from "../core/stores/RootStore";
import {useEffect, useState} from "react";
import {FormGroup, HTMLSelect, IOptionProps} from "@blueprintjs/core";
import {useField} from "formik";
import uuid from "react-uuid";
import {observer} from "mobx-react";

const SelectComponent: React.FC = () => {
    const {commonStore} = useRootStore();
    const [options, setOptions] = useState<IOptionProps[]>([]);

    const [field, meta, helpers] = useField(
        {
            name: "participantId"
        });

    const componentUUID = uuid();

    useEffect(() => {
        var newList: IOptionProps[] = [
            { value: "", label: "Please select your name..." }
        ];
        commonStore.participants.forEach((item) => {
            newList.push({
                value: item.participantId,
                label: item.name
            });
        })
        setOptions(newList);
    }, [commonStore.participants]);
    return (
        <FormGroup
            helperText={meta.touched && meta.error ? meta.error : undefined}
            intent={meta.touched && meta.error ? 'danger' : undefined}
            labelFor={componentUUID}
            disabled={commonStore.participant != undefined}
        >
            <HTMLSelect
                id={componentUUID}
                large={true}
                value={field.value}
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                options={options}
                disabled={commonStore.participant != undefined}
            />
        </FormGroup>
    )
}

export default observer(SelectComponent);